import Axios from 'axios'

export const classNames = ( ...classes ) => {
    return classes.filter( Boolean ).join( "" )
}

export const fetchRefreshToken = async () => {
    try {
        const response = await Axios.post( `http://localhost:8000/account/token/refresh/`, {
            refresh: localStorage.getItem( "refresh" )
        }  )

        if( response.data ){
            await localStorage.removeItem( "token" )
            await localStorage.setItem( "token", response.data.access )
        }

    } catch( error ){
        alert( "Error refreshing token" )
    }
}

