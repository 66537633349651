import React from 'react'

export default function ButtonField( { disabled = false, type = "submit", label } ) {
  return (
    <div className="w-full h-8">
        <input 
            type={type} 
            value={label} 
            disabled={disabled}
            className="w-full h-full text-center outline-none text-white border-[#276834] rounded-sm bg-[#276834] hover:bg-red-500 hover:outline-none" />
    </div>
  )
}
