import React from 'react'


export default function LoginFormWrapper( { children, label, showHeading } ) {
  return (
    <div className="flex w-full h-full items-center justify-center my-5">
    <div className="w-2/5 bg-white flex flex-col px-5 shadow-xl">

        <div className="w-full flex flex-col items-center justify-center p-3">
          { showHeading && ( 
            <span className="w-full text-center font-bold text-lg uppercase">
                NFA E-Licensing System
            </span> ) }

            <span className="w-full text-gray-500 text-center text-base">
                {label}
            </span>
        </div>

        <div className="w-full flex-grow p-2">
            {children}
        </div>
    </div>
</div>
  )
}
