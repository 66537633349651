import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isSigningIn: false,
    successLogin: null,
    successFailed: null,
    users: [],
    user: null
}

const AppSlice = createSlice( {
    name: "user",
    initialState,
    reducers: {
        signInRequest: ( state ) => {
            state.isSigningIn = true
        },
        signInSuccess: ( state, action ) =>  {
            state.isSigningIn = false
            state.successLogin = action.payload
        },
        signInFailed: ( state, action ) => {
            state.isSigningIn = false
            state.successFailed = action.payload
        },
        fetchAllUsers: state => {
            state.fetchingUsers = true
        }, 
        fetchUsersSuccess: ( state, action ) => {
            state.fetchingUsers = false 
            state.users = action.payload
            state.user = action.payload.users.filter( user => user.email === localStorage.getItem( "email" ) )?.[ 0 ]
        }, 
        fetchUsersFailed: ( state ) => {
            state.isSigningIn = false
        }
    }
} )

export const { 
        signInRequest, signInSuccess, signInFailed, 
        fetchAllUsers, fetchUsersSuccess, fetchUsersFailed 
    } = AppSlice.actions 

export default AppSlice.reducer