import React, { useEffect } from 'react'
import { TextFieldIcon, Navbar, ButtonField, LoginFormWrapper  } from '../../_components'
import { CircularProgress } from '@material-ui/core'
import { FaLock, FaUserAlt  } from 'react-icons/fa'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signInRequest, fetchAllUsers } from '../../redux/_slices/AppSlice'

export default function SignIn() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const state = useSelector( state => {
      return {
          success: state.app.successLogin
      }
  } )

  useEffect( () => {
    if( state.success ) {
        localStorage.clear()
        localStorage.setItem( "role", state.success.user_role )
        localStorage.setItem( "email", state.success.email )
        localStorage.setItem( "token", state.success.tokens?.access )
        localStorage.setItem( "refresh", state.success.tokens?.refresh )

        navigate( "/terms-of-reference" )
    }
  }, [  state.success ])

  const handleSubmit = async ( values, actions ) => {
    const payload = {
      email: values.email,
      password: values.password
    }

    console.log( payload )
    // if( values.email === "johndoe@gmail.com" ) {

    //     localStorage.clear()
    //     localStorage.setItem( "role", "Developer" )
    //     localStorage.setItem( "email", "johndoe@gmail.com" )
    //     localStorage.setItem( "token", "1234567" )
    //     localStorage.setItem( "refresh", "1232532637384387" )

    //     navigate( "/terms-of-reference" )
    // }
  }


  return (
    <div className="h-full w-full flex flex-col">
        <Navbar navigate={navigate} />
        <LoginFormWrapper showHeading={true} label="Login">
            <Formik initialValues={{
                email: "",
                password: "",
                user_role: ""
            }} onSubmit={handleSubmit}>
                { ( { handleSubmit, handleBlur, handleChange, errors, values, touched, isSubmitting } ) => {
                    return (
                        <form onSubmit={handleSubmit} className="grid grid-rows-2 gap-1">
                            <TextFieldIcon 
                                name="email"
                                type="email"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.email}
                                iconComponent={<FaUserAlt color={`#276834`} />} 
                                label="Email Address"
                                question="Your Email Address" />

                            <TextFieldIcon 
                                name="password"
                                type="password"
                                label="Password"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.password}
                                iconComponent={<FaLock color={`#276834`} 
                                question="Your Password"/>}
                            />

                            <div className="w-full py-1 grid grid-cols-1">
                                {isSubmitting ? <CircularProgress /> : <ButtonField label={`Sign In`} />}
                            </div>
                        </form>
                    )
                }  }
            </Formik>
            

            <div className="w-full flex items-center justify-center py-2">
                <button onClick={ () => navigate( "/forgot-password" )}>
                    <span className="text-center text-sm text-[#276834] p-2">
                        {`Forgot Password ?`}
                    </span>
                </button>
            </div>

            <div className="w-full flex items-center justify-center py-2">
                <button onClick={ () => navigate( "/register" )}>
                    <span className="text-center text-sm text-[#276834] p-2 underline">
                        {`If you don't have an account, Register here`}
                    </span>
                </button>
            </div>

        </LoginFormWrapper>

    </div>
  )
}
