import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
// import { Register, Login, ForgotPassword } from './_screens'
import SignIn from './_screens/Account/SignIn'
import Register from './_screens/Account/SignIn'
import ForgotPassword from './_screens/Account/SignIn'

const Home = () => {
  return (
    <div>Test</div>
  )
}

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<SignIn />}></Route>
        <Route exact path='/register' element={<Register />}></Route> 
        <Route exact path='/forgot-password' element={<ForgotPassword />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

