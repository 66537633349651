import Axios from 'axios'
const local = "http://localhost:8000/api/v1"
const url = "http://uat.eia.nema.go.ug/api/v1"

export function requestGetUser() { 
    return Axios.request({
      method: "get",
      url: "https://my-json-server.typicode.com/atothey/demo/user"
    });
}

export function requestSignIn( data ) {
    return Axios.request( {
        method: "post",
        url: `${url}/account/login/`,
        data
    } )
}

export function requestAllUsers() {
  return Axios.request( {
    method: "get",
    url: `${url}/account/user/`
  } )
}