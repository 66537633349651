import { call, put, takeLatest } from 'redux-saga/effects'
import { signInRequest, signInSuccess, signInFailed, fetchAllUsers, fetchUsersSuccess, fetchUsersFailed } from '../_slices/AppSlice'
import { requestSignIn, requestAllUsers } from './requests'

export function* watchSignIn () {
    yield takeLatest( signInRequest.type, postSignIn )
}

export function* postSignIn( action ) {
    try {
        const response = yield call( () => requestSignIn( action.payload ) )
        yield put( signInSuccess( response.data ) )
    } catch( error ){
       yield put( signInFailed( error ) )
    }
}

export function* watchFetchAllUsers() {
    yield takeLatest( fetchAllUsers.type, getAllUsers )
}

export function* getAllUsers() {
    try {
        const response = yield call( () => requestAllUsers() )
        yield put( fetchUsersSuccess( response.data ) )
    } catch( error ){
        yield put( fetchUsersFailed( error ) )
    }
}