import React from 'react'
import { classNames } from '../_utils'

import { at } from 'lodash'
import { useField } from 'formik'


const TextFieldIcon = ( { required = false, noPadding = false, disabled =  false, question = "", name, iconComponent, type =  "text", label, handleChange, handleBlur, value } ) => {
  
  const [ field, meta ] = useField( name )

  const _renderText = () => {
    const [ touched, error ] = at( meta, 'touched', 'error' ) 
    if( touched && error )
      return error
    
  }

  return (
    <div className={ classNames( noPadding ? "flex flex-col items-start" : "flex flex-col items-start my-2" ) }>
      <div className={ classNames( ( meta.touched && meta.error ) ? "inline-flex w-full rounded-sm border border-red-500" : "inline-flex w-full rounded-sm border border-[#276834]" ) }>
        <div className="w-1/6 flex items-center justify-center bg-gray-50">
          <span>{iconComponent}</span>
        </div>
        <input 
          name={name}
          disabled={disabled}
          id={name}
          type={type} 
          placeholder={label}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          required={required}
          autoComplete="off"
          className={ classNames( ( meta.touched && meta.error ) ? "w-full px-3 py-1 focus:outline-none focus:text-gray-600 placeholder-red-500 text-sm" : "w-full px-3 py-1 focus:outline-none focus:text-gray-600 placeholder-[#276834] text-sm" ) } 
        />
    </div>
    {(meta.touched && meta.error) ? (
      <p className="text-red-500 py-1" style={{ fontSize: "0.7rem" }}>
        {_renderText()}
    </p>
    ) : (
      <p className="text-[#276834] py-1" style={{ fontSize: "0.7rem" }}>
        {question}{ required && ( <span className="text-red-500">*</span>) }
      </p>
    ) }
    
  </div>
  )
}

export default TextFieldIcon
