import React from 'react'
import logo from '../_images/Logo.png'
import coatofArms from '../_images/uganda_coat_of_arms.png'
import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

// const NavLink = styled( LinkR )``

export default function Navbar( { navigate } ) {
  return (
    <div className="shadow-xl w-full">
        <div className="w-full h-auto  bg-[#276834] grid" style={{ gridTemplateColumns: "1fr 3fr 1fr" }}>
            
            <button onClick={ () => navigate( "/" ) } className="grid" style={{ gridTemplateRows: "1fr 1rem" }}>
                <div className="flex flex-row items-center justify-center py-2">
                    <div>
                        <img src={logo} className="object-contain h-10" alt="National Environment Management Authority" />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <span className="text-white  uppercase font-normal" style={{ fontSize: '0.65rem' }}>
                        E-Licensing System
                    </span>
                </div>
            </button>

            <div className="h-full"></div>
            <div className="h-full flex flex-col items-center justify-center">
                <div>
                    <img src={coatofArms} className="object-contain h-12" alt="National Environment Management Authority" />
                </div>
                <span className="text-white font-normal" style={{ fontSize: '0.65rem' }}>
                    GOU E-Services
                </span>
            </div>
        </div>
        
        <div className="h-1 max-h-1 bg-black w-full" />
        <div className="h-1 max-h-1 bg-yellow-300 w-full" />
        <div className="h-1 max-h-1 bg-red-500 w-full" />
    </div>
  )
}
