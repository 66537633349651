import { configureStore, combineReducers } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { logger } from 'redux-logger'
import rootSaga from './_sagas'
import userReducer from './_slices/AppSlice'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers( {
    app: userReducer
} )

const store  = configureStore( {
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat( sagaMiddleware, logger )
} )

sagaMiddleware.run( rootSaga )

export default store
